<template>
  <div class="all_game">
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <router-link :to="{name:'AllGame'}">&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;全部游戏
      </router-link>
    </div>
    <div class="list">
<!--      <div class="list_item logo">
        <img src="@/assets/image/AllGame/logo.png" alt="">
      </div>-->
      <div class="list_item search">
        <div class="search_tabs">
          <span :class="action.gameTypeId===''?'active':''" @click="getAllGameData">
            全部游戏
          </span>
          <span v-for="(item,index) in gameClass" :key="index" :class="action.gameTypeId === item.id?'active':''"
              @click="gameClassClick(item)">
              {{ item.class_name }}
          </span>
        </div>
        <div class="search_input">
          <input type="text" v-model="search_input_text" @keyup.enter="searchBtnClick" placeholder="请输入关键词进行搜索">
          <div class="search_btn" @click="searchBtnClick">
            <img src="@/assets/image/AllGame/search.png" alt="">
          </div>
        </div>
      </div>
      <div class="list_item opiton">
        <div>
          <span :class="action.firstChar===item?'active_A-Z':''" v-for="item in alphabet" :key="item" v-text="item"
              @click="onClickAlphabet(item)"></span>
        </div>
      </div>

      <div class="list_item history" v-if="historyGameList.length>0">
        <div class="historyTitle">
          <div class="titleText">
            最近浏览
          </div>
          <div class="clear" @mouseenter="clear = true" @mouseleave="clear = false" @click="clearHistory">
            <img v-if="!clear" src="@/assets/image/AllGame/gameDetails/clear.png" alt="">
            <img v-else src="@/assets/image/AllGame/gameDetails/clearHover.png" alt="">
            清空最近浏览
          </div>
        </div>
        <ul>
          <router-link v-for="item in historyGameList" :key="item.game_id"
              :to="item.game_id == '26'?{name:'SelectGame',params:{game_name:item.game_name,game_class:item.game_class,game_id:item.game_id,productTypeSelect:1},query:{game_id:item.game_id,game_alias:'yuanshen5'}}:{name:'SelectGame',params:{game_name:item.game_name,game_class:item.game_class,game_id:item.game_id,productTypeSelect:1},query:{game_id:item.game_id,game_alias:'games'}}">
            <div class="game_img"><img :src="item.game_image" alt=""></div>
            <div class="game_name">{{ item.game_name }}</div>
          </router-link>
        </ul>
      </div>
      <div class="list_item games">
        <ul>
          <router-link v-for="item in gameList" :key="item.game_id"
              :to="item.game_id == '26'?{name:'SelectGame',params:{game_name:item.game_name,game_class:item.game_class,game_id:item.game_id,productTypeSelect:1},query:{game_id:item.game_id,game_alias:'yuanshen5'}}:{name:'SelectGame',params:{game_name:item.game_name,game_class:item.game_class,game_id:item.game_id,productTypeSelect:1},query:{game_id:item.game_id,game_alias:'games'}}"
              @click.native="gamesClick(item)">
            <div class="game_img"><img :src="item.game_image" alt=""></div>
            <div class="game_name">{{ item.game_name }}</div>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {apiGameList, apiGameClass, apiHistoryList, apiHistoryAdd, apiHistoryDel} from "@/request/API";
import storage from "@/util/storage";

export default {
  name: 'allgame',
  props: {},
  components: {},
  data() {
    return {
      //游戏类型 && 首字母
      action: {
        gameTypeId: '',
        firstChar: "全部",
      },
      //A-Z
      alphabet: [],
      //搜索框文本
      search_input_text: '',
      //游戏列表
      gameList: [
      ],
      gameClass: [],
      //最近浏览
      historyGameList: [],
      //清空最近浏览Hover
      clear: false,
    }
  },
  methods: {
    //首字母列表点击事件
    onClickAlphabet(item) {
      this.action.firstChar = item
      if (item === '全部') {
        item = ''
      }
      this.getGameList('', item, this.action.gameTypeId)
    },
    // 展开全部游戏 获取全部游戏列表
    getAllGameData() {
      this.action = {
        gameTypeId: '',
        firstChar: "全部",
      }
      this.getGameList()
    },
    // 游戏类型点击事件
    gameClassClick(data) {
      this.action = {
        gameTypeId: data.id,
        firstChar: "全部",
      }
      this.getGameList('', '', data.id)
    },
    //搜索按钮点击事件
    searchBtnClick() {
      this.action = {
        gameTypeId: '',
        firstChar: "全部",
      }
      this.getGameList(this.search_input_text, '', '')
    },
    /**
     * 获取全部游戏列表
     * @param match 搜索框
     * @param game_letter 首字母
     * @param game_class 游戏类别
     */
    getGameList(match, game_letter, game_class) {
      if (game_class === '网络游戏') {
        game_class = '1'
      } else if (game_class === '手机游戏') {
        game_class = '2'
      }
      apiGameList({
        match: match,
        game_letter: game_letter,
        class_id: game_class,
      }).then(res => {
        this.gameList = res.data
      })
    },
    /**
     *
     */
    getGameClass() {
      apiGameClass({}).then(res => {
        this.gameClass = res.data
      })
    },
    /**
     * 点击游戏
     * @param item 游戏
     */
    gamesClick(item) {
      let ary
      if (storage.getItem('historyGameList')) {
        ary = JSON.parse(storage.getItem('historyGameList'))
        if (ary.findIndex(items => items.game_id === item.game_id) === -1) {
          ary.unshift(item)
        } else {
          ary.splice(ary.findIndex(items => items.game_id === item.game_id), 1)
          ary.unshift(item)
        }
        ary = ary.slice(0, 10)
      } else {
        ary = []
        ary.unshift(item)
      }
      storage.setItem('historyGameList', JSON.stringify(ary))
    },

    /**
     * 清空最近浏览
     */
    clearHistory() {
      this.historyGameList = []
      storage.setItem('historyGameList', JSON.stringify(this.historyGameList))
    },

    /**
     * 获取最近浏览游戏列表
     */
    getHistoryList() {
      this.historyGameList = JSON.parse(storage.getItem('historyGameList'))
      this.historyGameList.forEach((item, index) => {
        if (item.game_name === '无板块游戏') {
          this.historyGameList.splice(index, 1)
        }
      })
    }
  },
  mounted() {
    //获取首字母列表
    for (var i = 0; i < 26; i++) {
      this.alphabet.push(String.fromCharCode(65 + i))
    }
    this.alphabet.unshift('全部')
    //默认获取全部游戏列表
    this.getAllGameData()
    this.getGameClass()
    this.getHistoryList()
  },
  watch: {},
  computed: {}
}
</script>

<style lang='less' scoped>
.all_game {
  width: 1200px;

  .title {
    height: 60px;
    display: flex;
    align-items: center;
    margin-left: 5px;

    a:first-of-type {
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }

    a:last-of-type {
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #434ED6;
    }

    a {
      .Breadcrumb {
        font-size: 12px;
      }
    }
  }

  .list {
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .list_item {
      width: 1160px;
    }

    .logo {
      height: 119px;
      border-radius: 20px;
      margin-top: 26px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
    }

    .search {
      height: 119px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .search_tabs {
        //width: 236px;
        display: flex;
        justify-content: space-between;

        span {
          margin: 0 30px 0 0;
          font-size: 20px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          padding-bottom: 4px;
          cursor: pointer;
        }

        .active {
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          position: relative; //给当前元素添加相对定位,也就是after的父级
          &:after {
            content: " ";
            width: 68.18182%;
            height: 2px;
            background: #434ED6;
            position: absolute;
            bottom: -2px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .search_input {
        position: relative;

        input {
          width: 260px;
          height: 46px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #283748;
          padding: 0 62px 0 28px;
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #5B5B5B;
        }

        input::-webkit-input-placeholder {
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #E2E2E2;
        }

        .search_btn {
          width: 41px;
          height: 41px;
          position: absolute;
          top: 13px;
          left: 305px;

          img {
            width: 24.66px;
            height: 23.66px;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
    }

    .opiton {
      height: 56px;
      background: #F4F4F4;
      border-radius: 40px;

      div {
        height: 56px;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          padding: 0 14px;
        }

        span:hover {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #434ED6;
          cursor: pointer;
        }

        .active_A-Z {
          color: #434ED6 !important;
        }
      }
    }

    .games, .history {
      margin-bottom: 26px;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        width: 1180px;
        padding-top: 30px;

        li, a {
          width: 98px;
          height: 130px;
          margin: 0 20px 22px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .game_img {
            width: 98px;
            height: 98px;
            border-radius: 30px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              border-radius: 30px;
              object-fit: cover;
              transition: 0.3s ease-in-out;
            }
          }

          .game_name {
            width: 130px;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #5B5B5B;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        li:hover {
          .game_img {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .history {
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 0px;

      .historyTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .titleText {
          padding: 20px 0;
          font-size: 18px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #000000;
        }

        .clear {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 500;
          color: #b4b4b4;
          cursor: pointer;
          display: flex;
          align-items: center;

          img {
            margin: 0 6px 0 0;
          }
        }

        .clear:hover {
          color: #434ED6 ;
        }

      }

      ul {
        padding: 0;
      }
    }
  }
}
</style>
